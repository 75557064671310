import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { urlBackend } from '../../js/const';
import MatchItem from './matchItem';
import '../../style/creategame.scss'

const CreateGame = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedChampionnat, setSelectedChampionnat] = useState('');
  const [scrapListLength, setScrapListLength] = useState(null);
  const [pageNumber, setPageNumber] = useState(1)
  const [scrappedListMatch, setScrappedListMatch] = useState(null);

  const handleChangePage = async (event) => {
    setIsLoading(true)
    const value = event.target.value;
    setScrapListLength(value);
  }

  const handleSelectChampionnat = async (event) => {
    setIsLoading(true)
    const value = event.target.value;
    setSelectedChampionnat(value);

    if (value) {
      try {
        const response = await axios.get(`${urlBackend}scrapListLenght`, {
            params: { championnat: value }
        });
        setScrapListLength(response.data);
        setPageNumber(response.data)
      } catch (error) {
        console.error('Erreur lors de la requête', error);
      }
    }
    setIsLoading(false)
  };

  const scrapListMatch = async () => {
    try {
      const response = await axios.get(`${urlBackend}scrapListMatch`, {
        params: { championnat: selectedChampionnat, page: scrapListLength }
      });
      setScrappedListMatch(response.data.matches);
    } catch (error) {
      console.error('Erreur lors de la requête', error);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (scrapListLength) {
      scrapListMatch();
    }
  }, [scrapListLength]);

  return (
    <div className='create'>
      <h1>Créer une partie</h1>

      <div className='create__filter'>
        <label htmlFor="gameType">Compétitions</label>

        <select id="gameType" value={selectedChampionnat} onChange={handleSelectChampionnat}>
          <option value="">Selectionner un compétition</option>
          <option value="ligue-1">Ligue 1</option>
          <option value="ligue-2">Ligue 2</option>
          <option value="premier-league">Premier League</option>
          <option value="bundesliga">Bundesliga</option>
          <option value="liga-1-division">Liga</option>
          <option value="serie-a">Serie A</option>
          <option value="world-cup">Coupe du Monde</option>
          <option value="champions-league">Ligue des champions</option>

        </select>

        <label htmlFor="gameType">Page</label>

        {scrapListLength &&
          <select id="pageSelect" value={scrapListLength} onChange={handleChangePage}>
            {[...Array(Number(pageNumber)).keys()].map((i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        }
      </div>

        {
          isLoading ? 
            <div className="loader_Container">
              <span className="loader"></span>
              <p>Chargement...</p>
            </div>
          : 
            <table>
              <tbody>
                {scrappedListMatch && scrappedListMatch.map((item, index) => (
                  <MatchItem key={index} data={item} />
                ))}
              </tbody>
            </table>
        }
      
    </div>
  );
};

export default CreateGame;

