import React from 'react';
import { Link } from 'react-router-dom';

export default class Component__Navbar extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }

    render() {
      return(
        <div>
          <Link className='Link' to="/">HOME</Link>
          <Link className='Link' to="/CreateGame">CREATE GAME</Link>
          <Link className='Link' to="/joingame">JOIN GAME</Link>
          <Link className='Link' to="/game">PLAY GAME</Link>
        </div>
      ) ;
    }
  }