import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import supabase from '../../js/supabaseClient';
import { checkResponse, nextTurn, removeLife, sendValue } from './function';
import '../../style/game.scss'
import LifeCounter from './lifeCounter';

const Game = () => {
    const navigate = useNavigate()
    const params = useParams()

    const [lastResponseWasGood, setLastResponseWasGood] = useState(null)
    const [guessValue, setGuessValue] = useState("")
    const [game, setGame] = useState(null)
    const [gameIsOver, setGameIsOver] = useState(false)
    const [isPlayerA, setIsPlayerA] = useState(true)
 
    const channels = supabase.channel('custom-update-channel')
    .on('postgres_changes',{ 
        event: 'UPDATE', schema: 'public', table: 'trouve_le_onze' },
        (payload) => {
            setGame(payload.new)
        }
    )
    .subscribe()

    const fetchGame = async () =>{
        const { data: gameData, error: findError } = await supabase.from('trouve_le_onze')
        .select("*")
        .eq('id', params.id.toUpperCase());

        if(gameData && !findError){
            console.log(gameData);
            setGame(gameData[0])
        }
        else{
            alert('une erreur est survenu')
        }
    }
    
    const handleChange = async (event) =>{
        setGuessValue(event.target.value)
    }

    const handleClick = async (event) =>{
        if(guessValue?.length > 1){
            const isGoodResponse = checkResponse(guessValue, game.datagame, game.datatofind)
            console.log(isGoodResponse);
            if(isGoodResponse === "All Ready Find"){
                alert('Ce joeur est déja trouvé')
            }
            else if(isGoodResponse === false){
                setLastResponseWasGood(false)
                await removeLife(game, isPlayerA)
                //await nextTurn(game)
            }
            else{
                setLastResponseWasGood(true)
                await sendValue(game, isGoodResponse)
                //await nextTurn (game) 
            }
        } 
    }

    useEffect(() =>{
        fetchGame()
    },[params.id])

    useEffect(() =>{
        if(game){
            if(localStorage.getItem('player') !== game.playerA && localStorage.getItem('player') !== game.playerB){
                navigate('/joingame')
            } 
            else if (localStorage.getItem('player') === game.playerA) setIsPlayerA(true)
            else if (localStorage.getItem('player') === game.playerB) setIsPlayerA(false)
        }
    },[game])

    useEffect(() =>{
        if(game?.lifeA === 0 || game?.lifeB === 0){
            localStorage.removeItem('codegame')
            setGameIsOver(true)
        }
    },[game])
    
    return (
        <>
            {
                (game && game.id) ?
                <div className='game'>
                    <div className='game__topbar'>
                        <div className={`game__topbar__playerA ${isPlayerA ? 'active' : ''}`}>
                            {game.playerA ? 
                                <p>{game.playerA}</p> :
                                <p>En attente ...</p>
                            }
                            <LifeCounter life={game.lifeA} />
                        </div>
                        <div className='game__topbar__code'>
                            <Link to={'/'}>Accueil</Link><br/>
                            <span>Code de la partie : {params.id}</span>
                        </div>
                        <div className={`game__topbar__playerB ${!isPlayerA ? 'active' : ''}`}>
                            {game.playerB ? 
                                <p>{game.playerB}</p> :
                                <p>En attente ...</p>
                            }
                            <LifeCounter life={game.lifeB} />
                        </div>
                    </div>
                    <div className='game__title'>
                            <h2>
                                <span className='game__title__green'>{game.datatofind ? game.datatofind.joueurEquipeA.length : 0} / 11</span>
                                {game.teamA} - {game.teamB}
                                <span className='game__title__green'>{game.datatofind ? game.datatofind.joueurEquipeB.length : 0} / 11</span>
                            </h2>
                            <p>{game.date}</p>
                    </div>

                    <div className='game__main'>
                        <div className='game__main__team teamA'>
                            <span className='game__main__team__title'> Joueurs </span>
                            {/* {game.datatofind && 
                                <span className='spanPlayer'>Entraineur : {game.datatofind.entraineurEquipeA ? game.datatofind.entraineurEquipeA : ""}</span>
                            } */}
                            {gameIsOver ? (
                                game.datagame && game.datagame.joueurEquipeA.map((item, index) => (
                                    <span className='spanPlayer' key={index}>{item}</span>
                                ))
                            ) : (
                                game.datatofind && game.datatofind.joueurEquipeA.map((item, index) => (
                                    <span className='spanPlayer' key={index}>{item}</span>
                                ))
                            )}
                        </div>

                        <div className='game__main__center'>
                            <img src={game.logoA} alt="Logo" />
                            <img src={game.logoB} alt="Logo" />
                        </div>
                        <div className='game__main__team teamB'>
                            <span className='game__main__team__title'> Joueurs </span>
                            {/* {game.datatofind && 
                                <span className='spanPlayer'>Entraineur : {game.datatofind.entraineurEquipeB ? game.datatofind.entraineurEquipeA : ""}</span>
                            } */}
                            {gameIsOver ? (
                                game.datagame && game.datagame.joueurEquipeB.map((item, index) => (
                                    <span className='spanPlayer' key={index}>{item}</span>
                                ))
                            ) : (
                                game.datatofind && game.datatofind.joueurEquipeB.map((item, index) => (
                                    <span className='spanPlayer green' key={index}>{item}</span>
                                ))
                            )}
                        </div>
                    </div>

                    <div className='game__footer'>
                        {gameIsOver
                        ?
                            <div className='game__footer__end'>
                                <p>La partie est terminée ! {game.lifeA === 0 ? game.playerB+" a gagné" : game.playerA+" a gagné"}</p>
                                <Link to="/joingame">Retour au menu</Link>
                            </div>
                        :
                            <div className='game__footer__guess'>
                                {((game.isTurnA && isPlayerA)||(!game.isTurnA && !isPlayerA))? 
                                    <div>
                                        <input 
                                        placeholder='Nom du joueur'
                                        type="text"
                                        value={guessValue}
                                        onChange={handleChange} 
                                        />
                                        <button onClick={handleClick}>Guess</button>
                                    </div>
                                :
                                    <p className='game__footer__text'>
                                        {lastResponseWasGood === null && null }
                                        {lastResponseWasGood === false && <span className='error'> Mauvaise réponse ...</span> }
                                        {lastResponseWasGood === true && <span className='green'> Bonne réponse !</span> }
                                        &nbsp;En attente de ton adversaire</p>
                                }
                            </div>
                        }
                    </div>
                </div>
                : <span>Chargement...</span>
            }
        </>
    )
}

export default Game
