//GENERAL
import { Route, Routes, useLocation } from 'react-router-dom';

//COMP
import Home from './pages/Home';
import Component__Navbar from './components/Component__Navbar';
import { ToastContainer, toast } from 'react-toastify';

//STYLE 
import './index.scss';
import CreateGame from './pages/CreateGame';
import JoinGame from './pages/JoinGame';
import Game from './pages/Game';

function App(){

  const location = useLocation();

  return (
    <>
      {/* <Component__Navbar/> */}
      <Routes location={location} key={location.pathname}>
        <Route index element={<Home/>} />
        <Route path='/creategame' element={<CreateGame/>} />
        <Route path='/joingame' element={<JoinGame/>} />
        <Route path='/game/:id' element={<Game />} />
      </Routes>
    </>
  );
}

export default App;

