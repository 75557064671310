import React from "react";
import { Link } from "react-router-dom";
import '../../style/home.scss'

function Home(){
    return (
      <div className='home'>
        <h1>Trouve le onze</h1>
        <p>Choissis un match et essaye de retrouver les joueurs des deux 11 de départ. À chaque erreur tu perds une vie, le dernier joueur encore en vie remporte la match !</p>
        <Link to={"/creategame"}>Créer une partie</Link>
        <Link to={"/joingame"}>Rejoindre une partie</Link>
      </div>
    );
  }
  
  export default Home;