import React from 'react';

const LifeCounter = ({ life }) => {
  return (
    <div className='lifeCounter'>
      {Array.from({ length: 3 }, (_, index) => (
        <div
          key={index}
          className={`lifeCounter__life ${index < life ? 'active' : ''}`}
        ></div>
      ))}
    </div>
  );
}

export default LifeCounter;
