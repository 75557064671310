import React from 'react'
import { urlBackend } from '../../js/const';
import axios from 'axios';
import { generateCode } from '../../js/helper';
import supabase from '../../js/supabaseClient';
import { useNavigate } from 'react-router-dom';

const MatchItem = (props) => {
    const navigate = useNavigate()

    const handleClick = async () =>{
        try {
            const response = await axios.get(`${urlBackend}scrapMatchPlayers`, {
                params: { url: 'https://footballia.net'+props.data.matchLink }
            });
            console.log(response.data.match[0]);

            const codegame = generateCode()
            let logo1 = 'https://footballia.net'+props.data.teams[0].logo
            let logo2 = 'https://footballia.net'+props.data.teams[1].logo
 
            const { data, error } = await supabase.from('trouve_le_onze')
            .insert([
                { 
                    id: codegame, 
                    datagame: response.data.match[0],
                    date: props.data.playingDate,
                    teamA: props.data.teams[0].name,
                    teamB : props.data.teams[1].name,
                    logoA: logo1.replace(/small_/g, ''),
                    logoB: logo2.replace(/small_/g, ''),
                },
            ])
            .select()

            if(data && !error){
                localStorage.setItem('codegame', codegame)
                navigate('/joingame')
            }
//TNOPSW
          } catch (error) {
            alert('Erreur lors de la requête')
            console.error('Erreur lors de la requête', error);
          }
    }
    return (
        <tr>
            <td>{props.data.teams[0].name}</td>
            <td><img src={"https://footballia.net"+props.data.teams[0].logo} alt="Logo" /></td>
            <td><img src={"https://footballia.net"+props.data.teams[1].logo} alt="Logo" /></td>
            <td>{props.data.teams[1].name}</td>
            <td>{props.data.playingDate}</td>
            <td><button onClick={handleClick}>Jouer</button></td>
        </tr>
    )
}

export default MatchItem
