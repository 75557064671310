import React, { useEffect, useState } from 'react';
import supabase from '../../js/supabaseClient';
import { useNavigate } from 'react-router-dom';
import '../../style/joingame.scss'

const JoinGame = () => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({ code: '', username: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Code:', formData.code);
        console.log('Username:', formData.username);
    
        const { data: trouve_le_onze, error: findError } = await supabase
        .from('trouve_le_onze')
        .select("*")
        .eq('id', formData.code);
    
        if (findError) {
            console.error('Error finding the game:', findError);
            return;
        }
    
        if (trouve_le_onze && trouve_le_onze.length > 0) {
        const game = trouve_le_onze[0];
        let updateField = null;
    
        if (!game.playerA) {
            updateField = { playerA: formData.username };
        } else if (!game.playerB) {
            updateField = { playerB: formData.username };
        } else if (game.playerA === formData.username || game.playerB === formData.username) {
            localStorage.setItem('player', formData.username)
            navigate('/game/'+formData.code)
            console.log('User already part of the game');
            return;
        } else {
            alert('La partie est complète');
            return;
        }
    
        if (updateField) {
            const { data: updatedgame, error: updateError } = await supabase
            .from('trouve_le_onze')
            .update(updateField)
            .eq('id', formData.code)
            .select();
    
            if (updateError) {
                console.error('Error updating the game:', updateError);
            } else if (updatedgame) {
                localStorage.setItem('player', formData.username)
                navigate('/game/'+formData.code)
                console.log('Game updated:', updatedgame);
            }
        }
        } else {
            console.log('Game not found');
        }
    };
  
    useEffect(() =>{
        const gamecode = localStorage.getItem('codegame')
        console.log(gamecode);
        if(gamecode){
            setFormData({
                code: gamecode
            })
        }
    },[])

    return (
        <div className='join'>
            <h1>Rejoindre une partie</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="code">Game Code:</label>
                    <input
                        type="text"
                        id="code"
                        name="code"
                        value={formData.code}
                        onChange={handleChange}
                        required
                        placeholder='Code de partie'
                    />
                </div>
                <div>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                        placeholder='Username'
                    />
                </div>
                <button type="submit">Join</button>
            </form>
        </div>
    );
};

export default JoinGame;
