import supabase from "../../js/supabaseClient";
import { compareStrings } from "../../js/helper";

export const checkResponse = (guessValue, gameArr, toFindArr) =>{
    let result = false

    gameArr.joueurEquipeA.forEach(item => {
        const isSimilar = compareStrings(item, guessValue)
        if(isSimilar){
            result = checkIfAllReadyFind(item, toFindArr, "joueurEquipeA")
        }
    });
    gameArr.joueurEquipeB.forEach(item => {
        const isSimilar = compareStrings(item, guessValue)
        if(isSimilar){
            result = checkIfAllReadyFind(item, toFindArr, "joueurEquipeB")
        }
    });
    return result
}

export const checkIfAllReadyFind = (guessValue, toFindArr, location) => {
    let returnedArr;
    if (toFindArr === null) {
        returnedArr = {
            entraineurEquipeA: null,
            entraineurEquipeB: null,
            joueurEquipeA: [],
            joueurEquipeB: []
        };
    } else {
        returnedArr = { ...toFindArr };
    }

    switch (location) {
        case "entraineurEquipeA":
            if (!returnedArr.entraineurEquipeA !== guessValue) {
                returnedArr.entraineurEquipeA = guessValue;
            }
            else return 'All Ready Find'
            break;
        case "entraineurEquipeB":
            if (!returnedArr.entraineurEquipeB !== guessValue) {
                returnedArr.entraineurEquipeB = guessValue;
            }
            else return 'All Ready Find'
            break;
        case "joueurEquipeA":
            if (!returnedArr.joueurEquipeA.includes(guessValue)) {
                returnedArr.joueurEquipeA.push(guessValue);
            }
            else return 'All Ready Find'
            break;
        case "joueurEquipeB":
            if (!returnedArr.joueurEquipeB.includes(guessValue)) {
                returnedArr.joueurEquipeB.push(guessValue);
            }
            else return 'All Ready Find'
            break;
        default:
            alert("Error");
    }
    console.log(returnedArr);
    return returnedArr
}

export const sendValue = async (datagame, toFindArr) => {
    console.log(toFindArr);
    try {
        const { data, error } = await supabase
            .from('trouve_le_onze')
            .update({ 
                datatofind: toFindArr,
                isTurnA : !datagame.isTurnA
             })
            .eq('id', datagame.id)
            .select();

        if (error) {
            throw error;
        }
        console.log('Data updated successfully:', data);
        return data
    } catch (error) {
        console.error('Error updating data:', error.message);
        return;
    }
};

export const removeLife = async(datagame, isPlayerA) =>{
    try {
        let payload = isPlayerA ? {lifeA: (datagame.lifeA - 1), isTurnA : !datagame.isTurnA} : {lifeB: (datagame.lifeB - 1), isTurnA : !datagame.isTurnA}
        const { data, error } = await supabase
            .from('trouve_le_onze')
            .update(payload)
            .eq('id', datagame.id)
            .select();

        if (error) {
            throw error;
        }
        console.log('Data updated successfully:', data);
        return data
    } catch (error) {
        console.error('Error updating data:', error.message);
        return;
    }
}

export const nextTurn = async (datagame) =>{
    try {
        const { data, error } = await supabase
            .from('trouve_le_onze')
            .update({isTurnA : !datagame.isTurnA})
            .eq('id', datagame.id)
            .select();

        if (error) {
            throw error;
        }
        console.log('Data updated successfully:', data);
        return data
    } catch (error) {
        console.error('Error updating data:', error.message);
        return;
    }
}
