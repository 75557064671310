// src/supabaseClient.js

import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://ktwovnnuasgfxcvukqqx.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt0d292bm51YXNnZnhjdnVrcXF4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTQ4NjA3ODIsImV4cCI6MjAxMDQzNjc4Mn0.OmPpIR8C-9KUtfZ7WLKM9P4gfRty7to3vPheWQA61pk';

const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;
